/*! jquery-qrcode v0.14.0 - https://larsjung.de/jquery-qrcode/ */
!(function (r) {
  'use strict';
  function t(t, e, n, o) {
    function a(r, t) {
      return (r -= o), (t -= o), 0 > r || r >= c || 0 > t || t >= c ? !1 : f.isDark(r, t);
    }
    function i(r, t, e, n) {
      var o = u.isDark,
        a = 1 / l;
      u.isDark = function (i, u) {
        var f = u * a,
          c = i * a,
          l = f + a,
          g = c + a;
        return o(i, u) && (r > l || f > e || t > g || c > n);
      };
    }
    var u = {},
      f = r(n, e);
    f.addData(t), f.make(), (o = o || 0);
    var c = f.getModuleCount(),
      l = f.getModuleCount() + 2 * o;
    return (
      (u.text = t),
      (u.level = e),
      (u.version = n),
      (u.moduleCount = l),
      (u.isDark = a),
      (u.addBlank = i),
      u
    );
  }
  function e(r, e, n, o, a) {
    (n = Math.max(1, n || 1)), (o = Math.min(40, o || 40));
    for (var i = n; o >= i; i += 1)
      try {
        return t(r, e, i, a);
      } catch (u) {}
  }
  function n(r, t, e) {
    var n = e.size,
      o = 'bold ' + e.mSize * n + 'px ' + e.fontname,
      a = w('<canvas/>')[0].getContext('2d');
    a.font = o;
    var i = a.measureText(e.label).width,
      u = e.mSize,
      f = i / n,
      c = (1 - f) * e.mPosX,
      l = (1 - u) * e.mPosY,
      g = c + f,
      s = l + u,
      v = 0.01;
    1 === e.mode ? r.addBlank(0, l - v, n, s + v) : r.addBlank(c - v, l - v, g + v, s + v),
      (t.fillStyle = e.fontcolor),
      (t.font = o),
      t.fillText(e.label, c * n, l * n + 0.75 * e.mSize * n);
  }
  function o(r, t, e) {
    var n = e.size,
      o = e.image.naturalWidth || 1,
      a = e.image.naturalHeight || 1,
      i = e.mSize,
      u = (i * o) / a,
      f = (1 - u) * e.mPosX,
      c = (1 - i) * e.mPosY,
      l = f + u,
      g = c + i,
      s = 0.01;
    3 === e.mode ? r.addBlank(0, c - s, n, g + s) : r.addBlank(f - s, c - s, l + s, g + s),
      t.drawImage(e.image, f * n, c * n, u * n, i * n);
  }
  function a(r, t, e) {
    w(e.background).is('img')
      ? t.drawImage(e.background, 0, 0, e.size, e.size)
      : e.background && ((t.fillStyle = e.background), t.fillRect(e.left, e.top, e.size, e.size));
    var a = e.mode;
    1 === a || 2 === a ? n(r, t, e) : (3 === a || 4 === a) && o(r, t, e);
  }
  function i(r, t, e, n, o, a, i, u) {
    r.isDark(i, u) && t.rect(n, o, a, a);
  }
  function u(r, t, e, n, o, a, i, u, f, c) {
    i ? r.moveTo(t + a, e) : r.moveTo(t, e),
      u ? (r.lineTo(n - a, e), r.arcTo(n, e, n, o, a)) : r.lineTo(n, e),
      f ? (r.lineTo(n, o - a), r.arcTo(n, o, t, o, a)) : r.lineTo(n, o),
      c ? (r.lineTo(t + a, o), r.arcTo(t, o, t, e, a)) : r.lineTo(t, o),
      i ? (r.lineTo(t, e + a), r.arcTo(t, e, n, e, a)) : r.lineTo(t, e);
  }
  function f(r, t, e, n, o, a, i, u, f, c) {
    i && (r.moveTo(t + a, e), r.lineTo(t, e), r.lineTo(t, e + a), r.arcTo(t, e, t + a, e, a)),
      u && (r.moveTo(n - a, e), r.lineTo(n, e), r.lineTo(n, e + a), r.arcTo(n, e, n - a, e, a)),
      f && (r.moveTo(n - a, o), r.lineTo(n, o), r.lineTo(n, o - a), r.arcTo(n, o, n - a, o, a)),
      c && (r.moveTo(t + a, o), r.lineTo(t, o), r.lineTo(t, o - a), r.arcTo(t, o, t + a, o, a));
  }
  function c(r, t, e, n, o, a, i, c) {
    var l = r.isDark,
      g = n + a,
      s = o + a,
      v = e.radius * a,
      h = i - 1,
      d = i + 1,
      w = c - 1,
      m = c + 1,
      y = l(i, c),
      T = l(h, w),
      p = l(h, c),
      B = l(h, m),
      A = l(i, m),
      E = l(d, m),
      k = l(d, c),
      M = l(d, w),
      C = l(i, w);
    y
      ? u(t, n, o, g, s, v, !p && !C, !p && !A, !k && !A, !k && !C)
      : f(t, n, o, g, s, v, p && C && T, p && A && B, k && A && E, k && C && M);
  }
  function l(r, t, e) {
    var n,
      o,
      a = r.moduleCount,
      u = e.size / a,
      f = i;
    for (e.radius > 0 && e.radius <= 0.5 && (f = c), t.beginPath(), n = 0; a > n; n += 1)
      for (o = 0; a > o; o += 1) {
        var l = e.left + o * u,
          g = e.top + n * u,
          s = u;
        f(r, t, e, l, g, s, n, o);
      }
    if (w(e.fill).is('img')) {
      (t.strokeStyle = 'rgba(0,0,0,0.5)'), (t.lineWidth = 2), t.stroke();
      var v = t.globalCompositeOperation;
      (t.globalCompositeOperation = 'destination-out'),
        t.fill(),
        (t.globalCompositeOperation = v),
        t.clip(),
        t.drawImage(e.fill, 0, 0, e.size, e.size),
        t.restore();
    } else (t.fillStyle = e.fill), t.fill();
  }
  function g(r, t) {
    var n = e(t.text, t.ecLevel, t.minVersion, t.maxVersion, t.quiet);
    if (!n) return null;
    var o = w(r).data('qrcode', n),
      i = o[0].getContext('2d');
    return a(n, i, t), l(n, i, t), o;
  }
  function s(r) {
    var t = w('<canvas/>').attr('width', r.size).attr('height', r.size);
    return g(t, r);
  }
  function v(r) {
    return w('<img/>').attr('src', s(r)[0].toDataURL('image/png'));
  }
  function h(r) {
    var t = e(r.text, r.ecLevel, r.minVersion, r.maxVersion, r.quiet);
    if (!t) return null;
    var n,
      o,
      a = r.size,
      i = r.background,
      u = Math.floor,
      f = t.moduleCount,
      c = u(a / f),
      l = u(0.5 * (a - c * f)),
      g = { position: 'relative', left: 0, top: 0, padding: 0, margin: 0, width: a, height: a },
      s = {
        position: 'absolute',
        padding: 0,
        margin: 0,
        width: c,
        height: c,
        'background-color': r.fill
      },
      v = w('<div/>').data('qrcode', t).css(g);
    for (i && v.css('background-color', i), n = 0; f > n; n += 1)
      for (o = 0; f > o; o += 1)
        t.isDark(n, o) &&
          w('<div/>')
            .css(s)
            .css({ left: l + o * c, top: l + n * c })
            .appendTo(v);
    return v;
  }
  function d(r) {
    return m && 'canvas' === r.render ? s(r) : m && 'image' === r.render ? v(r) : h(r);
  }
  var w = window.jQuery,
    m = (function () {
      var r = document.createElement('canvas');
      return !(!r.getContext || !r.getContext('2d'));
    })(),
    y = {
      render: 'canvas',
      minVersion: 1,
      maxVersion: 40,
      ecLevel: 'L',
      left: 0,
      top: 0,
      size: 200,
      fill: '#000',
      background: null,
      text: 'no text',
      radius: 0,
      quiet: 0,
      mode: 0,
      mSize: 0.1,
      mPosX: 0.5,
      mPosY: 0.5,
      label: 'no label',
      fontname: 'sans',
      fontcolor: '#000',
      image: null
    };
  w.fn.qrcode = function (r) {
    var t = w.extend({}, y, r);
    return this.each(function (r, e) {
      'canvas' === e.nodeName.toLowerCase() ? g(e, t) : w(e).append(d(t));
    });
  };
})(
  (function () {
    var r = (function () {
      function r(t, e) {
        if ('undefined' == typeof t.length) throw new Error(t.length + '/' + e);
        var n = (function () {
            for (var r = 0; r < t.length && 0 == t[r]; ) r += 1;
            for (var n = new Array(t.length - r + e), o = 0; o < t.length - r; o += 1)
              n[o] = t[o + r];
            return n;
          })(),
          o = {};
        return (
          (o.getAt = function (r) {
            return n[r];
          }),
          (o.getLength = function () {
            return n.length;
          }),
          (o.multiply = function (t) {
            for (
              var e = new Array(o.getLength() + t.getLength() - 1), n = 0;
              n < o.getLength();
              n += 1
            )
              for (var a = 0; a < t.getLength(); a += 1)
                e[n + a] ^= i.gexp(i.glog(o.getAt(n)) + i.glog(t.getAt(a)));
            return r(e, 0);
          }),
          (o.mod = function (t) {
            if (o.getLength() - t.getLength() < 0) return o;
            for (
              var e = i.glog(o.getAt(0)) - i.glog(t.getAt(0)), n = new Array(o.getLength()), a = 0;
              a < o.getLength();
              a += 1
            )
              n[a] = o.getAt(a);
            for (var a = 0; a < t.getLength(); a += 1) n[a] ^= i.gexp(i.glog(t.getAt(a)) + e);
            return r(n, 0).mod(t);
          }),
          o
        );
      }
      var t = function (t, e) {
        var o = 236,
          i = 17,
          l = t,
          g = n[e],
          s = null,
          v = 0,
          d = null,
          w = new Array(),
          m = {},
          y = function (r, t) {
            (v = 4 * l + 17),
              (s = (function (r) {
                for (var t = new Array(r), e = 0; r > e; e += 1) {
                  t[e] = new Array(r);
                  for (var n = 0; r > n; n += 1) t[e][n] = null;
                }
                return t;
              })(v)),
              T(0, 0),
              T(v - 7, 0),
              T(0, v - 7),
              A(),
              B(),
              k(r, t),
              l >= 7 && E(r),
              null == d && (d = D(l, g, w)),
              M(d, t);
          },
          T = function (r, t) {
            for (var e = -1; 7 >= e; e += 1)
              if (!(-1 >= r + e || r + e >= v))
                for (var n = -1; 7 >= n; n += 1)
                  -1 >= t + n ||
                    t + n >= v ||
                    ((e >= 0 && 6 >= e && (0 == n || 6 == n)) ||
                    (n >= 0 && 6 >= n && (0 == e || 6 == e)) ||
                    (e >= 2 && 4 >= e && n >= 2 && 4 >= n)
                      ? (s[r + e][t + n] = !0)
                      : (s[r + e][t + n] = !1));
          },
          p = function () {
            for (var r = 0, t = 0, e = 0; 8 > e; e += 1) {
              y(!0, e);
              var n = a.getLostPoint(m);
              (0 == e || r > n) && ((r = n), (t = e));
            }
            return t;
          },
          B = function () {
            for (var r = 8; v - 8 > r; r += 1) null == s[r][6] && (s[r][6] = r % 2 == 0);
            for (var t = 8; v - 8 > t; t += 1) null == s[6][t] && (s[6][t] = t % 2 == 0);
          },
          A = function () {
            for (var r = a.getPatternPosition(l), t = 0; t < r.length; t += 1)
              for (var e = 0; e < r.length; e += 1) {
                var n = r[t],
                  o = r[e];
                if (null == s[n][o])
                  for (var i = -2; 2 >= i; i += 1)
                    for (var u = -2; 2 >= u; u += 1)
                      -2 == i || 2 == i || -2 == u || 2 == u || (0 == i && 0 == u)
                        ? (s[n + i][o + u] = !0)
                        : (s[n + i][o + u] = !1);
              }
          },
          E = function (r) {
            for (var t = a.getBCHTypeNumber(l), e = 0; 18 > e; e += 1) {
              var n = !r && 1 == ((t >> e) & 1);
              s[Math.floor(e / 3)][(e % 3) + v - 8 - 3] = n;
            }
            for (var e = 0; 18 > e; e += 1) {
              var n = !r && 1 == ((t >> e) & 1);
              s[(e % 3) + v - 8 - 3][Math.floor(e / 3)] = n;
            }
          },
          k = function (r, t) {
            for (var e = (g << 3) | t, n = a.getBCHTypeInfo(e), o = 0; 15 > o; o += 1) {
              var i = !r && 1 == ((n >> o) & 1);
              6 > o ? (s[o][8] = i) : 8 > o ? (s[o + 1][8] = i) : (s[v - 15 + o][8] = i);
            }
            for (var o = 0; 15 > o; o += 1) {
              var i = !r && 1 == ((n >> o) & 1);
              8 > o
                ? (s[8][v - o - 1] = i)
                : 9 > o
                  ? (s[8][15 - o - 1 + 1] = i)
                  : (s[8][15 - o - 1] = i);
            }
            s[v - 8][8] = !r;
          },
          M = function (r, t) {
            for (
              var e = -1, n = v - 1, o = 7, i = 0, u = a.getMaskFunction(t), f = v - 1;
              f > 0;
              f -= 2
            )
              for (6 == f && (f -= 1); ; ) {
                for (var c = 0; 2 > c; c += 1)
                  if (null == s[n][f - c]) {
                    var l = !1;
                    i < r.length && (l = 1 == ((r[i] >>> o) & 1));
                    var g = u(n, f - c);
                    g && (l = !l), (s[n][f - c] = l), (o -= 1), -1 == o && ((i += 1), (o = 7));
                  }
                if (((n += e), 0 > n || n >= v)) {
                  (n -= e), (e = -e);
                  break;
                }
              }
          },
          C = function (t, e) {
            for (
              var n = 0, o = 0, i = 0, u = new Array(e.length), f = new Array(e.length), c = 0;
              c < e.length;
              c += 1
            ) {
              var l = e[c].dataCount,
                g = e[c].totalCount - l;
              (o = Math.max(o, l)), (i = Math.max(i, g)), (u[c] = new Array(l));
              for (var s = 0; s < u[c].length; s += 1) u[c][s] = 255 & t.getBuffer()[s + n];
              n += l;
              var v = a.getErrorCorrectPolynomial(g),
                h = r(u[c], v.getLength() - 1),
                d = h.mod(v);
              f[c] = new Array(v.getLength() - 1);
              for (var s = 0; s < f[c].length; s += 1) {
                var w = s + d.getLength() - f[c].length;
                f[c][s] = w >= 0 ? d.getAt(w) : 0;
              }
            }
            for (var m = 0, s = 0; s < e.length; s += 1) m += e[s].totalCount;
            for (var y = new Array(m), T = 0, s = 0; o > s; s += 1)
              for (var c = 0; c < e.length; c += 1) s < u[c].length && ((y[T] = u[c][s]), (T += 1));
            for (var s = 0; i > s; s += 1)
              for (var c = 0; c < e.length; c += 1) s < f[c].length && ((y[T] = f[c][s]), (T += 1));
            return y;
          },
          D = function (r, t, e) {
            for (var n = u.getRSBlocks(r, t), c = f(), l = 0; l < e.length; l += 1) {
              var g = e[l];
              c.put(g.getMode(), 4),
                c.put(g.getLength(), a.getLengthInBits(g.getMode(), r)),
                g.write(c);
            }
            for (var s = 0, l = 0; l < n.length; l += 1) s += n[l].dataCount;
            if (c.getLengthInBits() > 8 * s)
              throw new Error('code length overflow. (' + c.getLengthInBits() + '>' + 8 * s + ')');
            for (c.getLengthInBits() + 4 <= 8 * s && c.put(0, 4); c.getLengthInBits() % 8 != 0; )
              c.putBit(!1);
            for (;;) {
              if (c.getLengthInBits() >= 8 * s) break;
              if ((c.put(o, 8), c.getLengthInBits() >= 8 * s)) break;
              c.put(i, 8);
            }
            return C(c, n);
          };
        return (
          (m.addData = function (r) {
            var t = c(r);
            w.push(t), (d = null);
          }),
          (m.isDark = function (r, t) {
            if (0 > r || r >= v || 0 > t || t >= v) throw new Error(r + ',' + t);
            return s[r][t];
          }),
          (m.getModuleCount = function () {
            return v;
          }),
          (m.make = function () {
            y(!1, p());
          }),
          (m.createTableTag = function (r, t) {
            (r = r || 2), (t = 'undefined' == typeof t ? 4 * r : t);
            var e = '';
            (e += '<table style="'),
              (e += ' border-width: 0px; border-style: none;'),
              (e += ' border-collapse: collapse;'),
              (e += ' padding: 0px; margin: ' + t + 'px;'),
              (e += '">'),
              (e += '<tbody>');
            for (var n = 0; n < m.getModuleCount(); n += 1) {
              e += '<tr>';
              for (var o = 0; o < m.getModuleCount(); o += 1)
                (e += '<td style="'),
                  (e += ' border-width: 0px; border-style: none;'),
                  (e += ' border-collapse: collapse;'),
                  (e += ' padding: 0px; margin: 0px;'),
                  (e += ' width: ' + r + 'px;'),
                  (e += ' height: ' + r + 'px;'),
                  (e += ' background-color: '),
                  (e += m.isDark(n, o) ? '#000000' : '#ffffff'),
                  (e += ';'),
                  (e += '"/>');
              e += '</tr>';
            }
            return (e += '</tbody>'), (e += '</table>');
          }),
          (m.createImgTag = function (r, t) {
            (r = r || 2), (t = 'undefined' == typeof t ? 4 * r : t);
            var e = m.getModuleCount() * r + 2 * t,
              n = t,
              o = e - t;
            return h(e, e, function (t, e) {
              if (t >= n && o > t && e >= n && o > e) {
                var a = Math.floor((t - n) / r),
                  i = Math.floor((e - n) / r);
                return m.isDark(i, a) ? 0 : 1;
              }
              return 1;
            });
          }),
          m
        );
      };
      (t.stringToBytes = function (r) {
        for (var t = new Array(), e = 0; e < r.length; e += 1) {
          var n = r.charCodeAt(e);
          t.push(255 & n);
        }
        return t;
      }),
        (t.createStringToBytes = function (r, t) {
          var e = (function () {
              for (
                var e = s(r),
                  n = function () {
                    var r = e.read();
                    if (-1 == r) throw new Error();
                    return r;
                  },
                  o = 0,
                  a = {};
                ;

              ) {
                var i = e.read();
                if (-1 == i) break;
                var u = n(),
                  f = n(),
                  c = n(),
                  l = String.fromCharCode((i << 8) | u),
                  g = (f << 8) | c;
                (a[l] = g), (o += 1);
              }
              if (o != t) throw new Error(o + ' != ' + t);
              return a;
            })(),
            n = '?'.charCodeAt(0);
          return function (r) {
            for (var t = new Array(), o = 0; o < r.length; o += 1) {
              var a = r.charCodeAt(o);
              if (128 > a) t.push(a);
              else {
                var i = e[r.charAt(o)];
                'number' == typeof i
                  ? (255 & i) == i
                    ? t.push(i)
                    : (t.push(i >>> 8), t.push(255 & i))
                  : t.push(n);
              }
            }
            return t;
          };
        });
      var e = { MODE_NUMBER: 1, MODE_ALPHA_NUM: 2, MODE_8BIT_BYTE: 4, MODE_KANJI: 8 },
        n = { L: 1, M: 0, Q: 3, H: 2 },
        o = {
          PATTERN000: 0,
          PATTERN001: 1,
          PATTERN010: 2,
          PATTERN011: 3,
          PATTERN100: 4,
          PATTERN101: 5,
          PATTERN110: 6,
          PATTERN111: 7
        },
        a = (function () {
          var t = [
              [],
              [6, 18],
              [6, 22],
              [6, 26],
              [6, 30],
              [6, 34],
              [6, 22, 38],
              [6, 24, 42],
              [6, 26, 46],
              [6, 28, 50],
              [6, 30, 54],
              [6, 32, 58],
              [6, 34, 62],
              [6, 26, 46, 66],
              [6, 26, 48, 70],
              [6, 26, 50, 74],
              [6, 30, 54, 78],
              [6, 30, 56, 82],
              [6, 30, 58, 86],
              [6, 34, 62, 90],
              [6, 28, 50, 72, 94],
              [6, 26, 50, 74, 98],
              [6, 30, 54, 78, 102],
              [6, 28, 54, 80, 106],
              [6, 32, 58, 84, 110],
              [6, 30, 58, 86, 114],
              [6, 34, 62, 90, 118],
              [6, 26, 50, 74, 98, 122],
              [6, 30, 54, 78, 102, 126],
              [6, 26, 52, 78, 104, 130],
              [6, 30, 56, 82, 108, 134],
              [6, 34, 60, 86, 112, 138],
              [6, 30, 58, 86, 114, 142],
              [6, 34, 62, 90, 118, 146],
              [6, 30, 54, 78, 102, 126, 150],
              [6, 24, 50, 76, 102, 128, 154],
              [6, 28, 54, 80, 106, 132, 158],
              [6, 32, 58, 84, 110, 136, 162],
              [6, 26, 54, 82, 110, 138, 166],
              [6, 30, 58, 86, 114, 142, 170]
            ],
            n = 1335,
            a = 7973,
            u = 21522,
            f = {},
            c = function (r) {
              for (var t = 0; 0 != r; ) (t += 1), (r >>>= 1);
              return t;
            };
          return (
            (f.getBCHTypeInfo = function (r) {
              for (var t = r << 10; c(t) - c(n) >= 0; ) t ^= n << (c(t) - c(n));
              return ((r << 10) | t) ^ u;
            }),
            (f.getBCHTypeNumber = function (r) {
              for (var t = r << 12; c(t) - c(a) >= 0; ) t ^= a << (c(t) - c(a));
              return (r << 12) | t;
            }),
            (f.getPatternPosition = function (r) {
              return t[r - 1];
            }),
            (f.getMaskFunction = function (r) {
              switch (r) {
                case o.PATTERN000:
                  return function (r, t) {
                    return (r + t) % 2 == 0;
                  };
                case o.PATTERN001:
                  return function (r, t) {
                    return r % 2 == 0;
                  };
                case o.PATTERN010:
                  return function (r, t) {
                    return t % 3 == 0;
                  };
                case o.PATTERN011:
                  return function (r, t) {
                    return (r + t) % 3 == 0;
                  };
                case o.PATTERN100:
                  return function (r, t) {
                    return (Math.floor(r / 2) + Math.floor(t / 3)) % 2 == 0;
                  };
                case o.PATTERN101:
                  return function (r, t) {
                    return ((r * t) % 2) + ((r * t) % 3) == 0;
                  };
                case o.PATTERN110:
                  return function (r, t) {
                    return (((r * t) % 2) + ((r * t) % 3)) % 2 == 0;
                  };
                case o.PATTERN111:
                  return function (r, t) {
                    return (((r * t) % 3) + ((r + t) % 2)) % 2 == 0;
                  };
                default:
                  throw new Error('bad maskPattern:' + r);
              }
            }),
            (f.getErrorCorrectPolynomial = function (t) {
              for (var e = r([1], 0), n = 0; t > n; n += 1) e = e.multiply(r([1, i.gexp(n)], 0));
              return e;
            }),
            (f.getLengthInBits = function (r, t) {
              if (t >= 1 && 10 > t)
                switch (r) {
                  case e.MODE_NUMBER:
                    return 10;
                  case e.MODE_ALPHA_NUM:
                    return 9;
                  case e.MODE_8BIT_BYTE:
                    return 8;
                  case e.MODE_KANJI:
                    return 8;
                  default:
                    throw new Error('mode:' + r);
                }
              else if (27 > t)
                switch (r) {
                  case e.MODE_NUMBER:
                    return 12;
                  case e.MODE_ALPHA_NUM:
                    return 11;
                  case e.MODE_8BIT_BYTE:
                    return 16;
                  case e.MODE_KANJI:
                    return 10;
                  default:
                    throw new Error('mode:' + r);
                }
              else {
                if (!(41 > t)) throw new Error('type:' + t);
                switch (r) {
                  case e.MODE_NUMBER:
                    return 14;
                  case e.MODE_ALPHA_NUM:
                    return 13;
                  case e.MODE_8BIT_BYTE:
                    return 16;
                  case e.MODE_KANJI:
                    return 12;
                  default:
                    throw new Error('mode:' + r);
                }
              }
            }),
            (f.getLostPoint = function (r) {
              for (var t = r.getModuleCount(), e = 0, n = 0; t > n; n += 1)
                for (var o = 0; t > o; o += 1) {
                  for (var a = 0, i = r.isDark(n, o), u = -1; 1 >= u; u += 1)
                    if (!(0 > n + u || n + u >= t))
                      for (var f = -1; 1 >= f; f += 1)
                        0 > o + f ||
                          o + f >= t ||
                          ((0 != u || 0 != f) && i == r.isDark(n + u, o + f) && (a += 1));
                  a > 5 && (e += 3 + a - 5);
                }
              for (var n = 0; t - 1 > n; n += 1)
                for (var o = 0; t - 1 > o; o += 1) {
                  var c = 0;
                  r.isDark(n, o) && (c += 1),
                    r.isDark(n + 1, o) && (c += 1),
                    r.isDark(n, o + 1) && (c += 1),
                    r.isDark(n + 1, o + 1) && (c += 1),
                    (0 == c || 4 == c) && (e += 3);
                }
              for (var n = 0; t > n; n += 1)
                for (var o = 0; t - 6 > o; o += 1)
                  r.isDark(n, o) &&
                    !r.isDark(n, o + 1) &&
                    r.isDark(n, o + 2) &&
                    r.isDark(n, o + 3) &&
                    r.isDark(n, o + 4) &&
                    !r.isDark(n, o + 5) &&
                    r.isDark(n, o + 6) &&
                    (e += 40);
              for (var o = 0; t > o; o += 1)
                for (var n = 0; t - 6 > n; n += 1)
                  r.isDark(n, o) &&
                    !r.isDark(n + 1, o) &&
                    r.isDark(n + 2, o) &&
                    r.isDark(n + 3, o) &&
                    r.isDark(n + 4, o) &&
                    !r.isDark(n + 5, o) &&
                    r.isDark(n + 6, o) &&
                    (e += 40);
              for (var l = 0, o = 0; t > o; o += 1)
                for (var n = 0; t > n; n += 1) r.isDark(n, o) && (l += 1);
              var g = Math.abs((100 * l) / t / t - 50) / 5;
              return (e += 10 * g);
            }),
            f
          );
        })(),
        i = (function () {
          for (var r = new Array(256), t = new Array(256), e = 0; 8 > e; e += 1) r[e] = 1 << e;
          for (var e = 8; 256 > e; e += 1) r[e] = r[e - 4] ^ r[e - 5] ^ r[e - 6] ^ r[e - 8];
          for (var e = 0; 255 > e; e += 1) t[r[e]] = e;
          var n = {};
          return (
            (n.glog = function (r) {
              if (1 > r) throw new Error('glog(' + r + ')');
              return t[r];
            }),
            (n.gexp = function (t) {
              for (; 0 > t; ) t += 255;
              for (; t >= 256; ) t -= 255;
              return r[t];
            }),
            n
          );
        })(),
        u = (function () {
          var r = [
              [1, 26, 19],
              [1, 26, 16],
              [1, 26, 13],
              [1, 26, 9],
              [1, 44, 34],
              [1, 44, 28],
              [1, 44, 22],
              [1, 44, 16],
              [1, 70, 55],
              [1, 70, 44],
              [2, 35, 17],
              [2, 35, 13],
              [1, 100, 80],
              [2, 50, 32],
              [2, 50, 24],
              [4, 25, 9],
              [1, 134, 108],
              [2, 67, 43],
              [2, 33, 15, 2, 34, 16],
              [2, 33, 11, 2, 34, 12],
              [2, 86, 68],
              [4, 43, 27],
              [4, 43, 19],
              [4, 43, 15],
              [2, 98, 78],
              [4, 49, 31],
              [2, 32, 14, 4, 33, 15],
              [4, 39, 13, 1, 40, 14],
              [2, 121, 97],
              [2, 60, 38, 2, 61, 39],
              [4, 40, 18, 2, 41, 19],
              [4, 40, 14, 2, 41, 15],
              [2, 146, 116],
              [3, 58, 36, 2, 59, 37],
              [4, 36, 16, 4, 37, 17],
              [4, 36, 12, 4, 37, 13],
              [2, 86, 68, 2, 87, 69],
              [4, 69, 43, 1, 70, 44],
              [6, 43, 19, 2, 44, 20],
              [6, 43, 15, 2, 44, 16],
              [4, 101, 81],
              [1, 80, 50, 4, 81, 51],
              [4, 50, 22, 4, 51, 23],
              [3, 36, 12, 8, 37, 13],
              [2, 116, 92, 2, 117, 93],
              [6, 58, 36, 2, 59, 37],
              [4, 46, 20, 6, 47, 21],
              [7, 42, 14, 4, 43, 15],
              [4, 133, 107],
              [8, 59, 37, 1, 60, 38],
              [8, 44, 20, 4, 45, 21],
              [12, 33, 11, 4, 34, 12],
              [3, 145, 115, 1, 146, 116],
              [4, 64, 40, 5, 65, 41],
              [11, 36, 16, 5, 37, 17],
              [11, 36, 12, 5, 37, 13],
              [5, 109, 87, 1, 110, 88],
              [5, 65, 41, 5, 66, 42],
              [5, 54, 24, 7, 55, 25],
              [11, 36, 12, 7, 37, 13],
              [5, 122, 98, 1, 123, 99],
              [7, 73, 45, 3, 74, 46],
              [15, 43, 19, 2, 44, 20],
              [3, 45, 15, 13, 46, 16],
              [1, 135, 107, 5, 136, 108],
              [10, 74, 46, 1, 75, 47],
              [1, 50, 22, 15, 51, 23],
              [2, 42, 14, 17, 43, 15],
              [5, 150, 120, 1, 151, 121],
              [9, 69, 43, 4, 70, 44],
              [17, 50, 22, 1, 51, 23],
              [2, 42, 14, 19, 43, 15],
              [3, 141, 113, 4, 142, 114],
              [3, 70, 44, 11, 71, 45],
              [17, 47, 21, 4, 48, 22],
              [9, 39, 13, 16, 40, 14],
              [3, 135, 107, 5, 136, 108],
              [3, 67, 41, 13, 68, 42],
              [15, 54, 24, 5, 55, 25],
              [15, 43, 15, 10, 44, 16],
              [4, 144, 116, 4, 145, 117],
              [17, 68, 42],
              [17, 50, 22, 6, 51, 23],
              [19, 46, 16, 6, 47, 17],
              [2, 139, 111, 7, 140, 112],
              [17, 74, 46],
              [7, 54, 24, 16, 55, 25],
              [34, 37, 13],
              [4, 151, 121, 5, 152, 122],
              [4, 75, 47, 14, 76, 48],
              [11, 54, 24, 14, 55, 25],
              [16, 45, 15, 14, 46, 16],
              [6, 147, 117, 4, 148, 118],
              [6, 73, 45, 14, 74, 46],
              [11, 54, 24, 16, 55, 25],
              [30, 46, 16, 2, 47, 17],
              [8, 132, 106, 4, 133, 107],
              [8, 75, 47, 13, 76, 48],
              [7, 54, 24, 22, 55, 25],
              [22, 45, 15, 13, 46, 16],
              [10, 142, 114, 2, 143, 115],
              [19, 74, 46, 4, 75, 47],
              [28, 50, 22, 6, 51, 23],
              [33, 46, 16, 4, 47, 17],
              [8, 152, 122, 4, 153, 123],
              [22, 73, 45, 3, 74, 46],
              [8, 53, 23, 26, 54, 24],
              [12, 45, 15, 28, 46, 16],
              [3, 147, 117, 10, 148, 118],
              [3, 73, 45, 23, 74, 46],
              [4, 54, 24, 31, 55, 25],
              [11, 45, 15, 31, 46, 16],
              [7, 146, 116, 7, 147, 117],
              [21, 73, 45, 7, 74, 46],
              [1, 53, 23, 37, 54, 24],
              [19, 45, 15, 26, 46, 16],
              [5, 145, 115, 10, 146, 116],
              [19, 75, 47, 10, 76, 48],
              [15, 54, 24, 25, 55, 25],
              [23, 45, 15, 25, 46, 16],
              [13, 145, 115, 3, 146, 116],
              [2, 74, 46, 29, 75, 47],
              [42, 54, 24, 1, 55, 25],
              [23, 45, 15, 28, 46, 16],
              [17, 145, 115],
              [10, 74, 46, 23, 75, 47],
              [10, 54, 24, 35, 55, 25],
              [19, 45, 15, 35, 46, 16],
              [17, 145, 115, 1, 146, 116],
              [14, 74, 46, 21, 75, 47],
              [29, 54, 24, 19, 55, 25],
              [11, 45, 15, 46, 46, 16],
              [13, 145, 115, 6, 146, 116],
              [14, 74, 46, 23, 75, 47],
              [44, 54, 24, 7, 55, 25],
              [59, 46, 16, 1, 47, 17],
              [12, 151, 121, 7, 152, 122],
              [12, 75, 47, 26, 76, 48],
              [39, 54, 24, 14, 55, 25],
              [22, 45, 15, 41, 46, 16],
              [6, 151, 121, 14, 152, 122],
              [6, 75, 47, 34, 76, 48],
              [46, 54, 24, 10, 55, 25],
              [2, 45, 15, 64, 46, 16],
              [17, 152, 122, 4, 153, 123],
              [29, 74, 46, 14, 75, 47],
              [49, 54, 24, 10, 55, 25],
              [24, 45, 15, 46, 46, 16],
              [4, 152, 122, 18, 153, 123],
              [13, 74, 46, 32, 75, 47],
              [48, 54, 24, 14, 55, 25],
              [42, 45, 15, 32, 46, 16],
              [20, 147, 117, 4, 148, 118],
              [40, 75, 47, 7, 76, 48],
              [43, 54, 24, 22, 55, 25],
              [10, 45, 15, 67, 46, 16],
              [19, 148, 118, 6, 149, 119],
              [18, 75, 47, 31, 76, 48],
              [34, 54, 24, 34, 55, 25],
              [20, 45, 15, 61, 46, 16]
            ],
            t = function (r, t) {
              var e = {};
              return (e.totalCount = r), (e.dataCount = t), e;
            },
            e = {},
            o = function (t, e) {
              switch (e) {
                case n.L:
                  return r[4 * (t - 1) + 0];
                case n.M:
                  return r[4 * (t - 1) + 1];
                case n.Q:
                  return r[4 * (t - 1) + 2];
                case n.H:
                  return r[4 * (t - 1) + 3];
                default:
                  return;
              }
            };
          return (
            (e.getRSBlocks = function (r, e) {
              var n = o(r, e);
              if ('undefined' == typeof n)
                throw new Error('bad rs block @ typeNumber:' + r + '/errorCorrectLevel:' + e);
              for (var a = n.length / 3, i = new Array(), u = 0; a > u; u += 1)
                for (var f = n[3 * u + 0], c = n[3 * u + 1], l = n[3 * u + 2], g = 0; f > g; g += 1)
                  i.push(t(c, l));
              return i;
            }),
            e
          );
        })(),
        f = function () {
          var r = new Array(),
            t = 0,
            e = {};
          return (
            (e.getBuffer = function () {
              return r;
            }),
            (e.getAt = function (t) {
              var e = Math.floor(t / 8);
              return 1 == ((r[e] >>> (7 - (t % 8))) & 1);
            }),
            (e.put = function (r, t) {
              for (var n = 0; t > n; n += 1) e.putBit(1 == ((r >>> (t - n - 1)) & 1));
            }),
            (e.getLengthInBits = function () {
              return t;
            }),
            (e.putBit = function (e) {
              var n = Math.floor(t / 8);
              r.length <= n && r.push(0), e && (r[n] |= 128 >>> t % 8), (t += 1);
            }),
            e
          );
        },
        c = function (r) {
          var n = e.MODE_8BIT_BYTE,
            o = t.stringToBytes(r),
            a = {};
          return (
            (a.getMode = function () {
              return n;
            }),
            (a.getLength = function (r) {
              return o.length;
            }),
            (a.write = function (r) {
              for (var t = 0; t < o.length; t += 1) r.put(o[t], 8);
            }),
            a
          );
        },
        l = function () {
          var r = new Array(),
            t = {};
          return (
            (t.writeByte = function (t) {
              r.push(255 & t);
            }),
            (t.writeShort = function (r) {
              t.writeByte(r), t.writeByte(r >>> 8);
            }),
            (t.writeBytes = function (r, e, n) {
              (e = e || 0), (n = n || r.length);
              for (var o = 0; n > o; o += 1) t.writeByte(r[o + e]);
            }),
            (t.writeString = function (r) {
              for (var e = 0; e < r.length; e += 1) t.writeByte(r.charCodeAt(e));
            }),
            (t.toByteArray = function () {
              return r;
            }),
            (t.toString = function () {
              var t = '';
              t += '[';
              for (var e = 0; e < r.length; e += 1) e > 0 && (t += ','), (t += r[e]);
              return (t += ']');
            }),
            t
          );
        },
        g = function () {
          var r = 0,
            t = 0,
            e = 0,
            n = '',
            o = {},
            a = function (r) {
              n += String.fromCharCode(i(63 & r));
            },
            i = function (r) {
              if (0 > r);
              else {
                if (26 > r) return 65 + r;
                if (52 > r) return 97 + (r - 26);
                if (62 > r) return 48 + (r - 52);
                if (62 == r) return 43;
                if (63 == r) return 47;
              }
              throw new Error('n:' + r);
            };
          return (
            (o.writeByte = function (n) {
              for (r = (r << 8) | (255 & n), t += 8, e += 1; t >= 6; ) a(r >>> (t - 6)), (t -= 6);
            }),
            (o.flush = function () {
              if ((t > 0 && (a(r << (6 - t)), (r = 0), (t = 0)), e % 3 != 0))
                for (var o = 3 - (e % 3), i = 0; o > i; i += 1) n += '=';
            }),
            (o.toString = function () {
              return n;
            }),
            o
          );
        },
        s = function (r) {
          var t = r,
            e = 0,
            n = 0,
            o = 0,
            a = {};
          a.read = function () {
            for (; 8 > o; ) {
              if (e >= t.length) {
                if (0 == o) return -1;
                throw new Error('unexpected end of file./' + o);
              }
              var r = t.charAt(e);
              if (((e += 1), '=' == r)) return (o = 0), -1;
              r.match(/^\s$/) || ((n = (n << 6) | i(r.charCodeAt(0))), (o += 6));
            }
            var a = (n >>> (o - 8)) & 255;
            return (o -= 8), a;
          };
          var i = function (r) {
            if (r >= 65 && 90 >= r) return r - 65;
            if (r >= 97 && 122 >= r) return r - 97 + 26;
            if (r >= 48 && 57 >= r) return r - 48 + 52;
            if (43 == r) return 62;
            if (47 == r) return 63;
            throw new Error('c:' + r);
          };
          return a;
        },
        v = function (r, t) {
          var e = r,
            n = t,
            o = new Array(r * t),
            a = {};
          (a.setPixel = function (r, t, n) {
            o[t * e + r] = n;
          }),
            (a.write = function (r) {
              r.writeString('GIF87a'),
                r.writeShort(e),
                r.writeShort(n),
                r.writeByte(128),
                r.writeByte(0),
                r.writeByte(0),
                r.writeByte(0),
                r.writeByte(0),
                r.writeByte(0),
                r.writeByte(255),
                r.writeByte(255),
                r.writeByte(255),
                r.writeString(','),
                r.writeShort(0),
                r.writeShort(0),
                r.writeShort(e),
                r.writeShort(n),
                r.writeByte(0);
              var t = 2,
                o = u(t);
              r.writeByte(t);
              for (var a = 0; o.length - a > 255; )
                r.writeByte(255), r.writeBytes(o, a, 255), (a += 255);
              r.writeByte(o.length - a),
                r.writeBytes(o, a, o.length - a),
                r.writeByte(0),
                r.writeString(';');
            });
          var i = function (r) {
              var t = r,
                e = 0,
                n = 0,
                o = {};
              return (
                (o.write = function (r, o) {
                  if (r >>> o != 0) throw new Error('length over');
                  for (; e + o >= 8; )
                    t.writeByte(255 & ((r << e) | n)),
                      (o -= 8 - e),
                      (r >>>= 8 - e),
                      (n = 0),
                      (e = 0);
                  (n = (r << e) | n), (e += o);
                }),
                (o.flush = function () {
                  e > 0 && t.writeByte(n);
                }),
                o
              );
            },
            u = function (r) {
              for (var t = 1 << r, e = (1 << r) + 1, n = r + 1, a = f(), u = 0; t > u; u += 1)
                a.add(String.fromCharCode(u));
              a.add(String.fromCharCode(t)), a.add(String.fromCharCode(e));
              var c = l(),
                g = i(c);
              g.write(t, n);
              var s = 0,
                v = String.fromCharCode(o[s]);
              for (s += 1; s < o.length; ) {
                var h = String.fromCharCode(o[s]);
                (s += 1),
                  a.contains(v + h)
                    ? (v += h)
                    : (g.write(a.indexOf(v), n),
                      a.size() < 4095 && (a.size() == 1 << n && (n += 1), a.add(v + h)),
                      (v = h));
              }
              return g.write(a.indexOf(v), n), g.write(e, n), g.flush(), c.toByteArray();
            },
            f = function () {
              var r = {},
                t = 0,
                e = {};
              return (
                (e.add = function (n) {
                  if (e.contains(n)) throw new Error('dup key:' + n);
                  (r[n] = t), (t += 1);
                }),
                (e.size = function () {
                  return t;
                }),
                (e.indexOf = function (t) {
                  return r[t];
                }),
                (e.contains = function (t) {
                  return 'undefined' != typeof r[t];
                }),
                e
              );
            };
          return a;
        },
        h = function (r, t, e, n) {
          for (var o = v(r, t), a = 0; t > a; a += 1)
            for (var i = 0; r > i; i += 1) o.setPixel(i, a, e(i, a));
          var u = l();
          o.write(u);
          for (var f = g(), c = u.toByteArray(), s = 0; s < c.length; s += 1) f.writeByte(c[s]);
          f.flush();
          var h = '';
          return (
            (h += '<img'),
            (h += ' src="'),
            (h += 'data:image/gif;base64,'),
            (h += f),
            (h += '"'),
            (h += ' width="'),
            (h += r),
            (h += '"'),
            (h += ' height="'),
            (h += t),
            (h += '"'),
            n && ((h += ' alt="'), (h += n), (h += '"')),
            (h += '/>')
          );
        };
      return t;
    })();
    return (
      (function (r) {
        'function' == typeof define && define.amd
          ? define([], r)
          : 'object' == typeof exports && (module.exports = r());
      })(function () {
        return r;
      }),
      !(function (r) {
        r.stringToBytes = function (r) {
          function t(r) {
            for (var t = [], e = 0; e < r.length; e++) {
              var n = r.charCodeAt(e);
              128 > n
                ? t.push(n)
                : 2048 > n
                  ? t.push(192 | (n >> 6), 128 | (63 & n))
                  : 55296 > n || n >= 57344
                    ? t.push(224 | (n >> 12), 128 | ((n >> 6) & 63), 128 | (63 & n))
                    : (e++,
                      (n = 65536 + (((1023 & n) << 10) | (1023 & r.charCodeAt(e)))),
                      t.push(
                        240 | (n >> 18),
                        128 | ((n >> 12) & 63),
                        128 | ((n >> 6) & 63),
                        128 | (63 & n)
                      ));
            }
            return t;
          }
          return t(r);
        };
      })(r),
      r
    );
  })()
);
