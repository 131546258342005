import '@georapbox/a-tab-group/dist/a-tab-group.js';
import '@georapbox/web-share-element/dist/web-share-defined.js';
import '@georapbox/files-dropzone-element/dist/files-dropzone-defined.js';
import { isWebShareSupported } from '@georapbox/web-share-element/dist/is-web-share-supported.js';
import '@georapbox/resize-observer-element/dist/resize-observer-defined.js';
import { CapturePhoto } from '@georapbox/capture-photo-element/dist/capture-photo.js';
import { getSettings } from './services/storage.js';
import { toastAlert } from './toast-alert.js';
import { debounce } from './utils/debounce.js';
import moment from 'moment-timezone';
import * as htmlToImage from 'html-to-image';
import './custom-clipboard-copy.js';
import {
  BRANDS_API,
  CUSTOMER_CHECK,
  REGISTER_API,
  SHOPS_API,
  STATES_API,
  TOWNSHIPS_API
} from './constant.js';
import { saveAs } from 'file-saver';

(async function () {
  const NO_BARCODE_DETECTED = 'No barcode detected';
  const ACCEPTED_MIME_TYPES = [
    'image/jpg',
    'image/jpeg',
    'image/png',
    'image/apng',
    'image/gif',
    'image/webp',
    'image/avif'
  ];
  const tabGroupEl = document.querySelector('a-tab-group');
  const cameraPanel = document.getElementById('cameraPanel');
  const capturePhotoEl = document.querySelector('capture-photo');
  const cameraResultsEl = document.getElementById('cameraResults');
  const fileResultsEl = document.getElementById('fileResults');
  const scanBtn = document.getElementById('scanBtn');
  const dropzoneEl = document.getElementById('dropzone');
  const resizeObserverEl = document.querySelector('resize-observer');
  const scanFrameEl = document.getElementById('scanFrame');
  let shouldRepeatScan = false;
  let rafId;
  let is_indoor;
  let is_outdoor;
  let customer_id = null;
  let brands = [];
  let brand_id = null;

  if (!('BarcodeDetector' in window)) {
    try {
      await import('barcode-detector');
      log('Using BarcodeDetector polyfill.');
    } catch {
      tabGroupEl.style.display = 'none';
      return toastAlert('BarcodeDetector API is not supported by your browser.', 'danger');
    }
  } else {
    log('Using the native BarcodeDetector API.');
  }

  if (!isWebShareSupported()) {
    document.querySelectorAll('web-share').forEach(el => {
      el.hidden = true;
      el.disabled = true;
    });
  }

  capturePhotoEl.addEventListener(
    'capture-photo:video-play',
    evt => {
      scanFrameEl.hidden = false;
      resizeScanFrame(evt.detail.video);
      scan();

      const trackSettings = evt.target.getTrackSettings();
      const trackCapabilities = evt.target.getTrackCapabilities();
      const zoomLevelEl = document.getElementById('zoomLevel');

      if (trackSettings?.zoom && trackCapabilities?.zoom) {
        const zoomControls = document.getElementById('zoomControls');
        const minZoom = trackCapabilities?.zoom?.min || 0;
        const maxZoom = trackCapabilities?.zoom?.max || 10;
        let currentZoom = trackSettings?.zoom || 1;

        zoomControls.hidden = false;
        zoomLevelEl.textContent = currentZoom;

        zoomControls.addEventListener('click', evt => {
          const zoomInBtn = evt.target.closest('[data-action="zoom-in"]');
          const zoomOutBtn = evt.target.closest('[data-action="zoom-out"]');

          if (zoomInBtn && currentZoom < maxZoom) {
            currentZoom += 0.5;
          }

          if (zoomOutBtn && currentZoom > minZoom) {
            currentZoom -= 0.5;
          }

          zoomLevelEl.textContent = currentZoom;

          capturePhotoEl.zoom = currentZoom;
        });
      }
    },
    {
      once: true
    }
  );

  capturePhotoEl.addEventListener(
    'capture-photo:error',
    evt => {
      const error = evt.detail.error;

      if (error.name === 'NotFoundError') {
        // If the browser cannot find all media tracks with the specified types that meet the constraints given.
        return;
      }

      const errorMessage =
        error.name === 'NotAllowedError'
          ? 'Permission to use webcam was denied or video Autoplay is disabled. Reload the page to give appropriate permissions to webcam.'
          : error.message;

      cameraPanel.innerHTML = /* html */ `<div class="alert alert-danger" role="alert" style="margin: 0;">${errorMessage}</div>`;
    },
    {
      once: true
    }
  );

  CapturePhoto.defineCustomElement();

  dropzoneEl.accept = ACCEPTED_MIME_TYPES.join(',');

  const capturePhotoVideoEl = capturePhotoEl.shadowRoot.querySelector('video');
  const formats = await window.BarcodeDetector.getSupportedFormats();
  const barcodeDetector = new window.BarcodeDetector({ formats });

  const beep = (() => {
    const audioCtx = new (window.AudioContext ||
      window.webkitAudioContext ||
      window.audioContext)();

    if (!audioCtx) {
      return;
    }

    return async (duration, frequency, volume, type, callback) => {
      // if (!settings?.beep) {
      //   return;
      // }

      const oscillator = audioCtx.createOscillator();
      const gainNode = audioCtx.createGain();

      oscillator.connect(gainNode);
      gainNode.connect(audioCtx.destination);

      if (volume) {
        gainNode.gain.value = volume;
      }

      if (frequency) {
        oscillator.frequency.value = frequency;
      }

      if (type) {
        oscillator.type = type;
      }

      if (typeof callback === 'function') {
        oscillator.onended = callback;
      }

      oscillator.start(audioCtx.currentTime);
      oscillator.stop(audioCtx.currentTime + (duration || 500) / 1000);
    };
  })();

  async function vibrate(duration = 100) {
    if (typeof window.navigator.vibrate !== 'function') {
      return;
    }

    try {
      window.navigator.vibrate(duration);
    } catch {
      // Fail silently...
    }
  }

  function resizeScanFrame(videoEl) {
    if (!videoEl) {
      return;
    }

    const rect = videoEl.getBoundingClientRect();

    scanFrameEl.style.cssText = `width: ${rect.width}px; height: ${rect.height}px`;
  }

  function emptyResults(resultDialog) {
    resultDialog?.querySelector('.results__item')?.remove();
  }

  async function createResult(value, resultDialog) {
    if (!value || !resultDialog) {
      return;
    }

    emptyResults(resultDialog);

    let resultItem;

    try {
      const { value: settings } = await getSettings();

      new URL(value);
      resultItem = document.createElement('a');
      resultItem.href = value;

      if (!settings?.openWebPageSameTab) {
        resultItem.setAttribute('target', '_blank');
        resultItem.setAttribute('rel', 'noreferrer noopener');
      }

      if (settings?.openWebPage) {
        resultItem.click();
      }
    } catch {
      resultItem = document.createElement('span');
    }

    resultItem.className = 'results__item';
    resultItem.classList.toggle('results__item--no-barcode', value === NO_BARCODE_DETECTED);
    resultItem.textContent = value;
    stopScan();
    if (is_indoor) {
      $('#start_indoor_scan').html(value);
    }
    if (is_outdoor) {
      $('#start_outdoor_scan').html(value);
    }

    resultDialog.insertBefore(resultItem, resultDialog.querySelector('.results__actions'));

    const clipboarCopyEl = resultDialog.querySelector('custom-clipboard-copy');
    const webShareEl = resultDialog.querySelector('web-share');
    const isValidValue = value !== NO_BARCODE_DETECTED;

    if (clipboarCopyEl) {
      clipboarCopyEl.disabled = !isValidValue;
      clipboarCopyEl.hidden = !isValidValue;
    }

    if (webShareEl && isWebShareSupported()) {
      webShareEl.disabled = !isValidValue;
      webShareEl.hidden = !isValidValue;

      if (isValidValue) {
        webShareEl.setAttribute('share-text', value);
      } else {
        webShareEl.removeAttribute('share-text');
      }
    }

    resultDialog.show();
  }

  function detectBarcode(source) {
    return new Promise((resolve, reject) => {
      barcodeDetector
        .detect(source)
        .then(results => {
          if (Array.isArray(results) && results.length > 0) {
            resolve(results[0]);
          } else {
            reject({
              message: 'Could not detect barcode from provided source.'
            });
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  async function scan() {
    log('Scanning...');

    try {
      let barcode = {};
      barcode = await detectBarcode(capturePhotoVideoEl);
      window.cancelAnimationFrame(rafId);
      createResult(barcode.rawValue, cameraResultsEl);
      scanBtn.hidden = false;
      scanFrameEl.hidden = true;
      beep(200, 860, 0.03, 'square');
      vibrate();
      return;
    } catch {
      // Fail silently...
    }

    if (shouldRepeatScan) {
      rafId = window.requestAnimationFrame(() => scan());
    }
  }

  function handleFileSelect(file) {
    if (!file) {
      return;
    }

    const image = new Image();
    const reader = new FileReader();

    reader.onload = evt => {
      const data = evt.target.result;

      image.onload = async () => {
        try {
          const barcode = await detectBarcode(image);
          emptyResults(fileResultsEl);
          createResult(barcode.rawValue, fileResultsEl);
          beep(200, 860, 0.03, 'square');
          vibrate();
        } catch {
          emptyResults(fileResultsEl);
          createResult(NO_BARCODE_DETECTED, fileResultsEl);
        }
      };

      image.src = data;

      dropzoneEl.replaceChildren();

      const preview = document.createElement('div');
      preview.className = 'dropzone-preview';

      const imageWrapper = document.createElement('div');
      imageWrapper.className = 'dropzone-preview__image-wrapper';

      const fileNameWrapper = document.createElement('div');
      fileNameWrapper.className = 'dropzone-preview__file-name';
      fileNameWrapper.textContent = file.name;

      imageWrapper.appendChild(image);
      preview.appendChild(imageWrapper);
      preview.appendChild(fileNameWrapper);
      dropzoneEl.prepend(preview);
    };

    reader.readAsDataURL(file);
  }

  function log(...args) {
    process.env.NODE_ENV === 'development' && console.log(...args);
  }

  scanBtn.addEventListener('click', () => {
    scanBtn.hidden = true;
    scanFrameEl.hidden = false;
    emptyResults(cameraResultsEl);
    cameraResultsEl.close();
    scan();
  });

  function closeResultOverLay() {
    scanBtn.hidden = true;
    scanFrameEl.hidden = false;
    emptyResults(cameraResultsEl);
    cameraResultsEl.close();
    scan();
  }

  tabGroupEl.addEventListener(
    'a-tab-show',
    debounce(evt => {
      const tabId = evt.detail.tabId;

      if (tabId === 'cameraTab') {
        shouldRepeatScan = true;

        // Get the latest instance of capture-photo element to ensure we don't use the cached one.
        const capturePhotoEl = document.querySelector('capture-photo');

        if (!capturePhotoEl) {
          return;
        }

        if (!capturePhotoEl.loading && !cameraResultsEl.querySelector('.results__item')) {
          scan();
        }

        if (typeof capturePhotoEl.startVideoStream === 'function') {
          capturePhotoEl.startVideoStream();
        }
      }

      if (tabId === 'fileTab') {
        shouldRepeatScan = false;

        if (capturePhotoEl != null && typeof capturePhotoEl.stopVideoStream === 'function') {
          capturePhotoEl.stopVideoStream();
        }
      }
    }, 250)
  );

  // for download ticket
  const downloadTicket = async () => {
    $('#loader').toggleClass('hidden');
    $('#download_container').toggleClass('flex');
    $('#download_container').toggleClass('hidden');
    try {
      let dataUrl = await htmlToImage.toPng(document.getElementById('w_card'));
      dataUrl = await htmlToImage.toPng(document.getElementById('w_card'));
      dataUrl = await htmlToImage.toPng(document.getElementById('w_card'));
      let blob = await (await fetch(dataUrl)).blob();
      saveAs(blob, 'MCG_E_Warranty_Card');
      $('#loader').toggleClass('hidden');
      $('#section_1').toggleClass('hidden');
      $('#section_2').toggleClass('hidden');
      $('#download_container').toggleClass('flex');
      $('#download_container').toggleClass('hidden');
    } catch (error) {
      $('#loader').toggleClass('hidden');
      $('#download_container').toggleClass('flex');
      $('#download_container').toggleClass('hidden');
      console.log(error);
    }
  };

  $('#download_ticket').on('click', function () {
    downloadTicket();
  });

  function stopScan() {
    document.getElementById('scan_modal').hidden = true;

    shouldRepeatScan = false;

    if (capturePhotoEl != null && typeof capturePhotoEl.stopVideoStream === 'function') {
      capturePhotoEl.stopVideoStream();
    }
  }

  function startScan() {
    closeResultOverLay();
    document.getElementById('scan_modal').hidden = false;
    shouldRepeatScan = true;

    // Get the latest instance of capture-photo element to ensure we don't use the cached one.
    const capturePhotoEl = document.querySelector('capture-photo');

    if (!capturePhotoEl) {
      return;
    }

    if (!capturePhotoEl.loading && !cameraResultsEl.querySelector('.results__item')) {
      scan();
    }

    if (typeof capturePhotoEl.startVideoStream === 'function') {
      capturePhotoEl.startVideoStream();
    }
  }

  $(document).ready(function () {
    $('#prev_section').on('click', function () {
      $('#section_1').toggleClass('hidden');
      $('#section_2').toggleClass('hidden');
    });
    $('#next_section').on('click', function () {
      const name = $('#name').val();
      const phone = $('#phone').val();
      const nrc_no = $('#nrc_no').val();
      const nrc_states = $('#nrc_states').val();
      const nrc_townships = $('#nrc_townships').val();
      const address = $('#address').val();
      const state_id = $('#state_lists').val();
      const township_id = $('#township_lists').val();
      if (!nrc_states || !nrc_townships) {
        return toastAlert('ကျေးဇူးပြု၍ မှတ်ပုံတင် အချက်အလက်ထည့်ပါ။', 'danger');
      }
      if (!nrc_no) {
        return toastAlert('ကျေးဇူးပြု၍ မှတ်ပုံတင်နံပါတ်ထည့်ပါ။', 'danger');
      }
      if (!name) {
        return toastAlert('ကျေးဇူးပြု၍ အမည်ထည့်ပါ။', 'danger');
      }
      if (!phone) {
        return toastAlert('ကျေးဇူးပြု၍ ဖုန်းနံပါတ်ထည့်ပါ။', 'danger');
      }
      if (!state_id) {
        return toastAlert('ကျေးဇူးပြု၍ တိုင်းဒေသကြီး ရွေးချယ်ပေးပါ။', 'danger');
      }
      if (!township_id) {
        return toastAlert('ကျေးဇူးပြု၍ မြို့နယ် ရွေးချယ်ပေးပါ။', 'danger');
      }
      if (!address) {
        return toastAlert('ကျေးဇူးပြု၍ လိပ်စာထည့်ပါ။', 'danger');
      }
      $('#section_1').toggleClass('hidden');
      $('#section_2').toggleClass('hidden');
    });
    // for submit
    $('#submit_btn').on('click', function () {
      const name = $('#name').val();
      const phone = $('#phone').val();
      const nrc_no = $('#nrc_no').val();
      const nrc_states = $('#nrc_states').val();
      const nrc_townships = $('#nrc_townships').val();
      const nrc_types = $('#nrc_types').val();
      const address = $('#address').val();
      const state_id = $('#state_lists').val();
      const township_id = $('#township_lists').val();
      const brand_id = localStorage.getItem('brand_id');

      // for ac details
      const shop_id = $('#shop_lists').val();
      const type_id = $('#type_lists').val();
      const indoor_code = $('#start_indoor_scan').text();
      const outdoor_code = $('#start_outdoor_scan').text();
      if (!shop_id) {
        return toastAlert('ကျေးဇူးပြု၍ ဆိုင်ရွေးချယ်ပေးပါ။', 'danger');
      }
      if (!brand_id) {
        return toastAlert('ကျေးဇူးပြု၍ အမှတ်တံဆိပ်ရွေးချယ်ပေးပါ။', 'danger');
      }
      if (!type_id) {
        return toastAlert('ကျေးဇူးပြု၍ အမျိုးအစားရွေးချယ်ပေးပါ။', 'danger');
      }
      if (!indoor_code) {
        return toastAlert('ကျေးဇူးပြု၍ Indoor Code စကန်ဖတ်ပေးပါ။', 'danger');
      }
      if (!indoor_code === 'စကန်ဖတ်မည်။') {
        return toastAlert('ကျေးဇူးပြု၍ Indoor Code စကန်ဖတ်ပေးပါ။', 'danger');
      }
      if (!outdoor_code) {
        return toastAlert('ကျေးဇူးပြု၍ Outdoor Code စကန်ဖတ်ပေးပါ။', 'danger');
      }
      if (!outdoor_code === 'စကန်ဖတ်မည်။') {
        return toastAlert('ကျေးဇူးပြု၍ Outdoor Code စကန်ဖတ်ပေးပါ။', 'danger');
      }

      const data = {
        name,
        phone,
        nrc: `${nrc_states?.replace(' ', '')}${nrc_townships}${nrc_types}${nrc_no}`,
        address: {
          address,
          state_id,
          township_id
        },
        c_id: customer_id,
        shop_id,
        brand_id,
        type_id,
        indoor_code: indoor_code,
        outdoor_code: outdoor_code
      };
      console.log('data', data);

      $('#loader').toggleClass('hidden');

      const submitForm = async data => {
        await fetch(REGISTER_API, {
          headers: {
            'Content-Type': 'application/json'
          },
          method: 'POST',
          body: JSON.stringify(data)
        })
          .then(r => r.json())
          .then(res => {
            $('#loader').toggleClass('hidden');
            if (res?.success) {
              const data = res?.data;
              toastAlert(res.message, 'info');
              const warranty = data?.eWarranties?.[0];
              // const img_url = warranty?.brand?.logo;
              const brand = warranty?.brand?.name?.toLowerCase();
              // const url = `https://556-check-odds-node.vercel.app/napi/proxy/image?request_url=${encodeURIComponent(img_url)}`;

              if (brand === 'aufit') {
                $('#w_head_nav').toggleClass('bg-[var(--aufit-color)]');
                $('#w_foot_nav').toggleClass('bg-[var(--aufit-color)]');
                $('#w_brand_aufit').toggleClass('hidden');
              }

              if (brand === 'daikin') {
                $('#w_head_nav').toggleClass('bg-[var(--daikin-color)]');
                $('#w_foot_nav').toggleClass('bg-[var(--daikin-color)]');
                $('#w_brand_daikin').toggleClass('hidden');
              }

              if (brand === 'chigo') {
                $('#w_head_nav').toggleClass('bg-[var(--chigo-color)]');
                $('#w_foot_nav').toggleClass('bg-[var(--chigo-color)]');
                $('#w_brand_chigo').toggleClass('hidden');
              }

              if (brand !== 'aufit' && brand !== 'daikin' && brand !== 'chigo') {
                $('#w_head_nav').toggleClass('bg-[var(--mcg-color)]');
                $('#w_foot_nav').toggleClass('bg-[var(--mcg-color)]');
                $('#w_brand_mcg').toggleClass('hidden');
              }

              $('#w_c_id').val(data?.c_id);
              $('#w_name').val(data?.name);
              $('#w_phone').val(data?.phone);
              $('#w_nrc').val(data?.nrc);
              $('#w_shop').val(warranty?.shop?.name);

              $('#w_id').html(warranty?.uuid);
              $('#w_save_id').html(warranty?.uuid);
              $('#w_type').val(warranty?.type?.name);
              $('#w_model_no').val(warranty?.model_no);
              $('#w_indoor_code').val(warranty?.indoor_code);
              $('#w_outdoor_code').val(warranty?.outdoor_code);
              $('#w_purchase_date').val(`${moment().format('DD-MM-YYYY')}`);
              const year = warranty?.type?.warranty_years;
              $('#w_expired_date').val(
                `${moment()
                  .add(year, `year${year > 1 ? 's' : ''}`)
                  .format('DD-MM-YYYY')}`
              );
              $('#w_year').html(
                `${year} <span class="font-normal">Year${year > 1 ? 's' : ''}</span>`
              );
              $('#w_address').val(`${data?.address?.address}`);
              $('#w_address_township').val(`${data?.address?.township?.name}`);
              $('#w_address_state').val(`${data?.address?.state?.name}`);
              $('#w_qr').qrcode({
                width: 120,
                height: 120,
                text: warranty?.uuid
              });
              $('#w_qr_text').html(warranty?.uuid);
              $('#download_container').toggleClass('flex');
              $('#download_container').toggleClass('hidden');
              // setTimeout(async () => {
              //   downloadTicket(data);
              // }, 500);
            } else {
              toastAlert(
                res?.data?.indoor_code?.[0] ||
                  res?.data?.outdoor_code?.[0] ||
                  res?.data?.name?.[0] ||
                  res?.data?.phone?.[0] ||
                  res?.data?.nrc?.[0] ||
                  res?.data?.c_id?.[0] ||
                  res?.data?.brand_id?.[0] ||
                  res?.data?.shop_id?.[0] ||
                  res.message,
                'warning'
              );
            }
          })
          .catch(err => {
            console.log(err);
            $('#loader').toggleClass('hidden');
            toastAlert(err.message, 'danger');
          });
      };

      submitForm(data);
    });
  });

  $('#stop_scan').on('click', function () {
    stopScan();
  });

  $('#start_indoor_scan').on('click', function () {
    is_indoor = true;
    is_outdoor = false;
    startScan();
  });
  $('#start_outdoor_scan').on('click', function () {
    is_indoor = false;
    is_outdoor = true;
    startScan();
  });
  $('#scan_modal').on('click', function (e) {
    if (e.currentTarget === e.target) {
      stopScan();
    }
  });
  function selectBrand(type) {
    const type_instance = NiceSelect.bind(document.getElementById('type_lists'), {
      searchable: true,
      placeholder: 'Select'
    });
    const _find = brands.find(el => el.name.toLowerCase() === type);
    if (_find) {
      brand_id = _find.id;
      localStorage.setItem('brand_id', _find.id);
      $('#brand_section').toggleClass('hidden');
      $('#section_1').toggleClass('hidden');
      $(`#${type}_home_logo`).toggleClass('hidden');
      $('#mcg_title').toggleClass('hidden');
      const types = _find.types.map(el => {
        return `<option value="${el?.id}">${el?.name}</option>`;
      });
      $('#type_lists').html(['<option value="">Select</option>', ...types].join(''));
      type_instance.update();
    } else {
      toastAlert('Please refresh and try again!', 'danger');
    }
  }
  $('#brand_chigo').on('click', function () {
    selectBrand('chigo');
  });
  $('#brand_daikin').on('click', function () {
    selectBrand('daikin');
  });
  $('#brand_mcg').on('click', function () {
    selectBrand('mcg');
  });
  $('#brand_aufit').on('click', function () {
    selectBrand('aufit');
  });

  $(document).ready(function () {
    stopScan();
  });

  document.addEventListener('visibilitychange', () => {
    const selectedTab = tabGroupEl.querySelector('[selected]');
    const tabId = selectedTab.getAttribute('id');

    if (tabId !== 'cameraTab') {
      return;
    }

    if (document.visibilityState === 'hidden') {
      shouldRepeatScan = false;

      if (capturePhotoEl != null && typeof capturePhotoEl.stopVideoStream === 'function') {
        capturePhotoEl.stopVideoStream();
      }
    } else {
      shouldRepeatScan = true;

      // Get the latest instance of capture-photo element to ensure we don't use the cached one.
      const capturePhotoEl = document.querySelector('capture-photo');

      if (!capturePhotoEl) {
        return;
      }

      if (!capturePhotoEl.loading && !cameraResultsEl.querySelector('.results__item')) {
        scan();
      }

      if (typeof capturePhotoEl.startVideoStream === 'function') {
        capturePhotoEl.startVideoStream();
      }
    }
  });

  dropzoneEl.addEventListener('files-dropzone-drop', evt => {
    handleFileSelect(evt.detail.acceptedFiles[0]);
  });

  resizeObserverEl.addEventListener('resize-observer:resize', () => {
    resizeScanFrame(capturePhotoEl.shadowRoot.querySelector('video'));
  });

  // for start states
  $(document).ready(function () {
    const fetchStates = async () => {
      await fetch(STATES_API)
        .then(r => r.json())
        .then(res => {
          if (res?.success) {
            const states = res?.data?.map(el => {
              return `<option value="${el?.id}">${el?.name}</option>`;
            });
            $('#state_lists').html(['<option value="">Select</option>', ...states].join(''));
            NiceSelect.bind(document.getElementById('state_lists'), {
              searchable: true,
              placeholder: 'Select'
            });
          }
        })
        .catch(err => {
          console.log('err', err.message);
        });
    };
    fetchStates();
  });
  // for end states
  // for start townships
  $(document).ready(function () {
    const township_instance = NiceSelect.bind(document.getElementById('township_lists'), {
      searchable: true,
      placeholder: 'Select'
    });
    $('#state_lists').on('change', function () {
      const state_id = $(this).val();
      fetchTownships(state_id);
    });

    const fetchTownships = async id => {
      await fetch(TOWNSHIPS_API(id))
        .then(r => r.json())
        .then(res => {
          if (res?.success) {
            const townships = res?.data?.map(el => {
              return `<option value="${el?.id}">${el?.name}</option>`;
            });
            $('#township_lists').html(['<option value="">Select</option>', ...townships].join(''));
            township_instance.update();
          }
        })
        .catch(err => {
          console.log('err', err.message);
        });
    };
  });
  // for end townships
  // for start shops
  $(document).ready(function () {
    const shop_instance = NiceSelect.bind(document.getElementById('shop_lists'), {
      searchable: true,
      placeholder: 'Select'
    });
    $('#state_lists').on('change', function () {
      const state_id = $(this).val();
      fetchShops(state_id);
    });

    const fetchShops = async state_id => {
      await fetch(SHOPS_API + `?state_id=${state_id}`)
        .then(r => r.json())
        .then(res => {
          if (res?.success) {
            const shops = res?.data?.map(el => {
              return `<option value="${el?.id}">${el?.name}</option>`;
            });
            $('#shop_lists').html(['<option value="">Select</option>', ...shops].join(''));
            shop_instance.update();
          }
        })
        .catch(err => {
          console.log('err', err.message);
        });
    };
  });
  // for end shops
  // for start brands
  $(document).ready(function () {
    const fetchBrands = async () => {
      await fetch(BRANDS_API)
        .then(r => r.json())
        .then(res => {
          if (res?.success) {
            brands = res.data;
            //     const brands = res?.data?.map((el, i) => {
            //       const url = `https://556-check-odds-node.vercel.app/napi/proxy/image?request_url=${encodeURIComponent(el.logo)}`;
            //       const _item = `<div id="brand_item${i}" class="rounded-md bg-white shadow-md border border-red-200 p-3 w-full h-[80px] cursor-pointer">
            //       <span class="hidden">${el.id}</span>
            //   <img class="mx-auto" src="${url}" alt="Brand">
            // </div>`;
            //       return _item;
            //     });
            //     $('#home_brand_lists').html(brands);
          }
        })
        .catch(err => {
          console.log('err', err.message);
        });
    };
    fetchBrands();
  });
  // for end brands
  // for start nrc
  $(document).ready(function () {
    const nrc_townships_select = NiceSelect.bind(document.getElementById('nrc_townships'), {
      searchable: true,
      placeholder: 'Select'
    });

    const types = [
      { value: '(N)', label: '(နိုင်)' },
      { value: '(E)', label: '(ဧည့်)' },
      { value: '(P)', label: '(ပြု)' }
    ];
    const nrc_types = types?.map(el => {
      return `<option value="${el?.value}">${el?.label}</option>`;
    });

    const nrc_states = Array.from({ length: 14 }).map((_, i) => {
      return `<option value="${i + 1} /">${i + 1} /</option>`;
    });
    $('#nrc_states').html(nrc_states.join(''));
    NiceSelect.bind(document.getElementById('nrc_states'), {
      placeholder: 'Select'
    });
    $('#nrc_types').html(nrc_types.join(''));
    NiceSelect.bind(document.getElementById('nrc_types'), {
      placeholder: 'Select'
    });
    // for nrc townships
    const nrc = require('./nrc.json');
    $('#nrc_states').on('change', function () {
      const selectedValue = $(this).val();
      const filter = nrc?.data?.filter(el => el?.nrc_code + ' /' === selectedValue);
      const nrc_townships = filter?.map(el => {
        return `<option value="${el?.name_en}">${el?.name_mm?.replace('(', '')?.replace(')', '')?.split(' ')?.[0]}</option>`;
      });
      $('#nrc_townships').html(['<option value="">Select</option>', ...nrc_townships].join(''));
      nrc_townships_select.update();
    });

    $('#nrc_states').on('change', async function () {
      await checkUser();
    });
    $('#nrc_townships').on('change', async function () {
      await checkUser();
    });
    $('#nrc_types').on('change', async function () {
      await checkUser();
    });
    $('#nrc_no').on('change', async function () {
      await checkUser();
    });

    async function checkUser() {
      const nrc_state = $('#nrc_states').val();
      const nrc_township = $('#nrc_townships').val();
      const nrc_type = $('#nrc_types').val();
      const nrc_no = $('#nrc_no').val();
      if (nrc_state && nrc_township && nrc_type && nrc_no && nrc_no?.toString()?.length === 6) {
        const data = {
          nrc: `${nrc_state?.replace(' ', '')}${nrc_township}${nrc_type}${nrc_no}`
        };
        customer_id = null;
        $('loader').toggleClass('hidden');
        await fetch(CUSTOMER_CHECK, {
          headers: {
            'Content-Type': 'application/json'
          },
          method: 'POST',
          body: JSON.stringify(data)
        })
          .then(r => r.json())
          .then(res => {
            $('loader').toggleClass('hidden');
            if (res?.success) {
              const data = res?.data;
              $('#state_lists').val(data?.address?.state?.id);
              $('#name').val(data?.name);
              $('#phone').val(data?.phone);
              customer_id = data?.c_id;
              $('#address').val(data?.address?.address);
              $('#township_lists').val(data?.address?.township?.id);
            }
          })
          .catch(res => {
            $('loader').toggleClass('hidden');
            toastAlert(res?.message || 'Something went wrong!', 'danger');
          });
      }
    }
  });
  // for end nrc
})();
